/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-14",
    versionChannel: "nightly",
    buildDate: "2024-09-14T00:05:30.925Z",
    commitHash: "7d1373368b0f0b327f69f5d8751a4c49d1e266f1",
};
